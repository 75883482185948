import { Component, OnInit, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { map } from 'rxjs/operators';

export interface DialogData {
  name: string;
  biography: string;
  task: string;
}

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  selectedClient: DialogData;

  public clients: Observable<any[]>;

  constructor(private db: AngularFirestore, private storage: AngularFireStorage,  public dialog: MatDialog) {

    const clientAll = db.collection('/clients', ref => ref.orderBy('name', 'asc')).snapshotChanges();

    this.clients = clientAll.pipe(map(val => val.map(a => {
      const data = a.payload.doc.data() as any;
      let imageUrl = null;

      if(data.image !== ""){
        let imageDownload = storage.ref('clients/' + data.image).getDownloadURL();

        if(imageDownload){
          imageUrl = imageDownload;
        }
      }

      return {imageUrl, ...data };
    })));

  }

  openDialog(client: DialogData): void {

    this.selectedClient = client;

    const dialogRef = this.dialog.open(ClientDialog, {
      data: {name: client.name, biography: client.biography, task: client.task}
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  ngOnInit() {
  }

}

@Component({
  selector: 'client-dialog',
  templateUrl: 'client-dialog.html',
})
export class ClientDialog {

  constructor(
    public dialogRef: MatDialogRef<ClientDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}