interface Connection {
    name: string;
    industry: string;
}

export const CONNECTIONS: Connection[] = [
    {name: 'Dreamworks', industry: 'Film'},
    {name: '20TH Century Studios', industry: 'Film'},
    {name: 'New Line Cinema', industry: 'Film'},
    {name: 'Paramount', industry: 'Film'},
    {name: 'Sony Pictures', industry: 'Film'},
    {name: 'Universal', industry: 'Film'},
    {name: 'Walt Disney', industry: 'Film'},
    {name: 'Warner Brothers', industry: 'Film'},
    {name: 'ABC', industry: 'Television'},
    {name: 'A&E', industry: 'Television'},
    {name: 'Bravo', industry: 'Television'},
    {name: 'Comedy Central', industry: 'Television'},
    {name: 'ESPN', industry: 'Television'},
    {name: 'HBO', industry: 'Television'},
    {name: 'The History Channel', industry: 'Television'},
    {name: 'MTV', industry: 'Television'},
    {name: 'NBC', industry: 'Television'},
    {name: 'Showtime', industry: 'Television'},
    {name: 'Spike', industry: 'Television'},
    {name: 'TBS', industry: 'Television'},
    {name: 'TNT', industry: 'Television'},
    {name: 'Travel Channel', industry: 'Television'},
    {name: 'VH1', industry: 'Television'},
    {name: 'FitTV', industry: 'Television'},
    {name: 'EMI', industry: 'Music'},
    {name: 'Sony BMG Music Entertainment', industry: 'Music'},
    {name: 'Universal Music Group', industry: 'Music'},
    {name: 'Warner Music Group', industry: 'Music'}
]