import { Component, OnInit } from '@angular/core';
import { DOCUMENTARIES } from './documentary';

@Component({
  selector: 'app-documentary',
  templateUrl: './documentary.component.html',
  styleUrls: ['./documentary.component.scss']
})
export class DocumentaryComponent implements OnInit {

  documentaries = DOCUMENTARIES;

  constructor() { }

  ngOnInit() {
  }

}
