<div class="baer-featured">
  <p class="baer-featured-header">SCREENPLAY REVIEW</p>
  <p class="baer-featured-content">
    STAY AT HOME - WRITE THE NEXT GREAT MOVIE
  </p>
  <span class="baer-featured-button">
    <a mat-raised-button color="primary" routerLink="/script-review-services">Learn More</a>
  </span>
</div>
<h2>Entertainment Lawyer Ted Baer</h2>
<p>
  <img src="assets/images/ted_baer.jpg" style="float: left; padding: 0 10px  10px 0;">Ted Baer Law is a private law firm owned and operated by J. A. Ted Baer. The firm has specialized in transactional
  matters in the entertainment industry for over 40 years, and has worked with many prestigious names, companies and
  all of the major Hollywood film studios. The firm's reputation for expertise in negotiations and contractual matters
  is respected throughout the entertainment industry, and its connections, networking, and partnerships with
  entertainment companies is unparalleled.
</p>

<h2>International Entertainment Law Firm</h2>
<p>
  Since 1978, Ted Baer Law has been helping individuals and businesses in the entertainment industry worldwide. We advise
  individuals and businesses on contract negotiations, film libraries, rights deals, business negotiations, talent contracts,
  book deals and other entertainment law services. We are committed to serving our clients with professional, prompt, and timely service.
  Our experience in the marketplace, and our knowledge of Hollywood, allows us to provide exceptional representation in helping you achieve
  your entertainment goals.
</p>

<h2>Experience In Entertainment Law</h2>
<p>
  Ted Baer Law is an entertainment law firm that specializes in all facets of entertainment law, structuring deals, and also advising on
  packaging projects with effective creative resources.. We are dedicated to building long-term relationships with our clients.  Our notable
  past and current clients include studios, actors, entertainers, writers, cartoonists, film directors, and many more. Please see our "Client List"
  for specific examples. We have been instrumental in entertainment deals  worldwide, ranging from film production, to film financing, and film
  library sales. Our diverse clientele and decades of experience have earned Ted Baer Law a reputation as one of the preeminent entertainment law
  firms in the United States and beyond.
</p>

<h2>Contact Us For Your Entertainment Legal Needs</h2>
<p>
  For your convenience, evening and weekend appointments are available and will be accommodated. To learn how we can help you, please contact us at anytime.
</p>