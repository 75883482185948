import { Component, OnInit } from '@angular/core';
import { SERVICES } from './services';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit {

  services = SERVICES;

  constructor() { }

  ngOnInit() {
  }

}
