import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

export interface Article { article: string; title: string; date: string; }

@Component({
  selector: 'app-press-article',
  templateUrl: './press-article.component.html',
  styleUrls: ['./press-article.component.scss']
})

export class PressArticleComponent implements OnInit {

  private articleDoc: AngularFirestoreDocument<Article>;
  article: Observable<Article>;

  constructor(private db: AngularFirestore, private route: ActivatedRoute, private location: Location) {
    const articleId = this.route.snapshot.paramMap.get('id');
    this.articleDoc = db.doc<Article>('press/' + articleId);
    this.article = this.articleDoc.valueChanges();
  }

  update(article: Article) {
    this.articleDoc.update(article);
  }

  ngOnInit() {
  }

}
