<!-- Navigation -->
<!-- Toolbar -->
<mat-toolbar color="primary" class="baer-toolbar">
  <button class="mat-icon-button" mat-icon-button (click)="sidenav.toggle()"><mat-icon>menu</mat-icon></button>
  <h1>Law Offices Of Ted Baer</h1>
  <div class="flex-spacer"></div>
  <span class="show-on-large"><small>Call for free consultation: <strong>805-963-7177</strong></small></span>
</mat-toolbar>

<!-- Menu Dock -->
<mat-sidenav-container class="navigation-container">
  <mat-sidenav #sidenav mode="over">
    <nav class="baer-nav-menu">
      <a routerLink="/entertainment-lawyer-home" (click)="sidenav.toggle()">Home</a>
      <a routerLink="/entertainment-law-services" (click)="sidenav.toggle()">Services</a>
      <a routerLink="/entertainment-law-projects" (click)="sidenav.toggle()">Projects</a>
      <a routerLink="/entertainment-lawyer-clients" (click)="sidenav.toggle()">Clients</a>
      <a routerLink="/entertainment-law-industry-connections" (click)="sidenav.toggle()">Industry Connections</a>
      <a routerLink="/entertainment-lawyer-about-us" (click)="sidenav.toggle()">About Us</a>
      <!--
        // Hide
        <a routerLink="/entertainment-lawyer-contact-us" (click)="sidenav.toggle()">Contact Us</a>
      -->
      <a routerLink="/entertainment-lawyer-press" (click)="sidenav.toggle()">Press</a>
      <a routerLink="/entertainment-law-tips" (click)="sidenav.toggle()">Entertainment Law Tips</a>
      <a routerLink="/independent-films" (click)="sidenav.toggle()">Independent Films</a>
      <a routerLink="/documentary-films" (click)="sidenav.toggle()">Documentary Films</a>
      <a routerLink="/script-review-services" (click)="sidenav.toggle()">Script Review Services</a>
      <a routerLink="/clearance-services" (click)="sidenav.toggle()">Clearance Services</a>
      <a routerLink="/entertainment-law-articles" (click)="sidenav.toggle()">Entertainment Law Articles</a>
    </nav>
  </mat-sidenav>
  <mat-sidenav-content>
    <!-- Container -->
    <div class="baer-container">
        <!-- Site Navigation -->
        <!-- Header -->
        <div class="baer-header">
          <div class="baer-header-box">
            <h3>AN ENTERTAINMENT LAW FIRM</h3>
            <h1><span style="margin-bottom: 1em;">LAW OFFICES </span><br class="hide-on-large">OF TED BAER</h1>
            <h2>OVER 40 YEARS OF ENTERTAINMENT LAW EXPERIENCE</h2>
          </div>
        </div>
        <div class="hide-on-large text-align-center" style="padding: 0 15px 15px 15px;">
          <span>Call for free consultation: <strong>805-963-7177</strong></span>
        </div>
        <!-- Body content -->
        <div class="baer-container-columns">
          <div class="baer-content-main">
            <router-outlet></router-outlet>
          </div>
          <div class="baer-content-side">
            <div class="baer-side-spotlight">
              <p class="baer-side-spotlight-header">FREE CONSULTATION</p>
              <p class="baer-side-spotlight-text">
                Reach Us By Phone or Video
              </p>
              <span class="baer-side-spotlight-button">
                <a mat-raised-button color="secondary" href="tel:805-963-7177">Call Now</a>
                <a mat-raised-button color="secondary" href="skype:jatbiii">Skype</a>
              </span>
            </div>
            <div class="baer-contact-side">
              <app-contact></app-contact>
            </div>
          </div>
        </div>

        <!-- Footer-->
        <div class="baer-contact-bar">
          <div class="row">
            <div class="column">
              <button mat-icon-button aria-label="Phone" (click)="openPhoneDialog()">
                <mat-icon>phone</mat-icon>
              </button>
            </div>
            <div class="column">
                <button mat-icon-button aria-label="Email" (click)="openContactDialog()">
                    <mat-icon>email</mat-icon>
                  </button>
            </div>
            <div class="column">
                <button mat-icon-button aria-label="Map" (click)="openMapDialog()">
                    <mat-icon>location_on</mat-icon>
                  </button>
            </div>  
          </div>
        </div>
    <!-- End container -->
    </div>
    <div class="baer-disclaimer">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
              Legal Disclosure
            </mat-expansion-panel-header>
          
            <p>&copy;{{ currentYear }} Law Offices of Ted Baer.  All Rights Reserved.</p>

            <h4>Terms of Use</h4>
            <p>
              Communications made through the website do not create an attorney-client relationship.<br>
              Materials or information transmitted to the attorney or through or on the website may not remain confidential.<br>
              Content on the website should not be considered legal advice.<br>
              Results are not a guarantee or indicative of future success.
            </p>

          </mat-expansion-panel>
    </div>
    <!-- End Site Navigation -->
  </mat-sidenav-content>
</mat-sidenav-container>