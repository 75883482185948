<h1 mat-dialog-title>{{ data.name }}</h1>
<div mat-dialog-content>
  <div *ngIf="data.task">
    <h4>Services Rendered</h4>
    <p>{{ data.task }}</p>
  </div>
  <div *ngIf="data.biography">
    <h4>Client Biography</h4>
    <p>{{ data.biography }}</p>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Close</button>
</div>