interface Project {
    name: string;
    industry: string;
}

export const PROJECTS: Project[] = [
    {name: 'Yellow Roses', industry: 'Film'},
    {name: 'Tina Hutchison Story', industry: 'Film'},
    {name: 'Stray Hearts', industry: 'Film'},
    {name: 'Tanzania Choir Documentary ', industry: 'Film'},
    {name: 'Danny Way Theatrical Documentary', industry: 'Film'},
    {name: 'Carmen Miranda Story', industry: 'Film'},
    {name: 'Wrestling Movie', industry: 'Film'},
    {name: 'Street Dogs of South Central', industry: 'Film'},
    {name: 'Carlos Santana Documentary', industry: 'Film'},
    {name: 'Tupac Resurrection', industry: 'Film'},
    {name: 'Thelma and Louise', industry: 'Film'},
    {name: 'Who Is Clark Rockefelllar', industry: 'Film'},
    {name: 'Major The Movie', industry: 'Film'},
    {name: 'The Day My God Died', industry: 'Film'},
    {name: 'Camelot Entertainment Group', industry: 'Film'},
    {name: 'Skateland', industry: 'Film'},
    {name: 'The Fallen', industry: 'Film'},
    {name: 'Screwball - The Ted Whitfield Story', industry: 'Film'},
    {name: 'Who is KK Downey', industry: 'Film'},
    {name: 'Samurai Avenger', industry: 'Film'},
    {name: 'Next of Kin', industry: 'Film'},
    {name: 'Fink', industry: 'Film'},
    {name: 'First Strike', industry: 'Film'},
    {name: 'Never Sleep Again', industry: 'Film'},
    {name: 'National Lampoon\'s Dirty Movie', industry: 'Film'},
    {name: 'Poolboy - Drowing Out The Fury', industry: 'Film'},
    {name: 'A Warrior\'s Heart', industry: 'Film'},
    {name: 'Weiner Dog Nationals', industry: 'Film'},
    {name: 'Jackass 2', industry: 'Film'},
    {name: 'Jackass 3D', industry: 'Film'},
    {name: 'Everyday Gandhis', industry: 'TV'},
    {name: 'Gurus 2 Go', industry: 'TV'},
    {name: 'Derby Dolls', industry: 'TV'},
    {name: 'Paint Ball Syndicated TV', industry: 'TV'},
    {name: 'Addicted to Love', industry: 'TV'},
    {name: 'The Danger Show', industry: 'TV'},
    {name: 'Comedy Talk Show', industry: 'TV'},
    {name: 'Leonardo Da Vinci The Florence Project', industry: 'TV'},
    {name: 'The Adventures of Albert the Running Bear', industry: 'TV'},
    {name: 'SBCoupons.com', industry: 'Interactive'},
    {name: 'Historic Grand Prix', industry: 'TV'},
    {name: 'Fatal Charades', industry: 'TV'},
    {name: 'Attorney TV Series', industry: 'TV'},
    {name: 'Puff the Magic Dragon', industry: 'TV'},
    {name: 'Daniel Boone', industry: 'TV'},
    {name: 'Bet on a Wedding', industry: 'TV'},
    {name: 'Ren and Stimpy', industry: 'TV'},
    {name: 'Voice Sticker LLC', industry: 'Merchandise'},
    {name: 'Fort Knocks LTD', industry: 'Merchandise'},
    {name: 'Ressurection by Tupac', industry: 'Music'},
    {name: 'Better Dayz by Tupac', industry: 'Music'},
    {name: 'Until the End of Time by Tupac', industry: 'Music'},
    {name: 'Greatest Hits by Tupac', industry: 'Music'}
]