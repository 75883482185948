<h2>Script Review Services</h2>
<mat-card style="background-color: rgb(255, 255, 221);">
  <mat-card-header>
    <div mat-card-avatar>
      <mat-icon>new_releases</mat-icon>
    </div>
    <mat-card-title>DESCRIPTION</mat-card-title>
  </mat-card-header>
  <mat-card-content>
  <p>As the world hibernates in isolation, it is the perfect time to polish up your screenplays and submit them for development
   to executives at studios, networks or production companies. These executives, working from home for the most part, will be more available than usual.
   Let us review your screenplay to evaluate whether it is ready for submission or, in our opinion, whether it requires revision.  In this chaotic time,
   it is important to focus on positive action to help reduce the stress, but also to embrace the concept of not wasting a good crisis. Instead, let's use
   this time to move creative projects forward that you can do from home. If you are interested in pursuing this opportunity, please contact me at my contact
   information for a brief consultation to discuss your project.</p>
  </mat-card-content>
</mat-card>
<p>
  Tedbaerlaw.com provides script review services for aspiring screenwriters. It is the firm's policy to review a
  screenwriter's material prior to submitting it to potential agents, managers, producers, directors, performers,
  financiers or production companies, and handling the negotiations and drafting of all agreements associated with the
  option/purchase/production of a screenplay. The firm's standard policy is to charge a basic rate for the firm to read a
  screenplay without providing comment for the purpose of making a decision on whether the firm will agree to represent a
  particular screenplay, and an enhanced fee for both reading the screenplay and providing substantive feedback.  If the
  ultimate evaluation is that a screenplay is ready for submission, then we will negotiate a separate fee arrangement.  Please
  use our contact form for any inquiries.
</p>