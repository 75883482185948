class Service{
    name: string;
    description: string;
}

export const SERVICES: Service[] = [
    {name: '', description: 'Talent negotiations'},
    {name: '', description: 'Production financing'},
    {name: '', description: 'Distribution and completion of bond agreements'},
    {name: '', description: 'Rights negotiations and production legal'},
    {name: '', description: 'Rights clearance'},
    {name: '', description: 'Negotiations and Documentation for feature film and television deals'},
    {name: '', description: 'Negotiations and Documentation for publishing deals'},
    {name: '', description: 'Negotiations for film libraries'}
  ];