import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { ArticlesComponent } from './articles/articles.component';
import { ArticleDetailComponent } from './article-detail/article-detail.component';
import { ClearanceComponent } from './clearance/clearance.component';
import { ClientsComponent } from './clients/clients.component';
import { ConnectionsComponent } from './connections/connections.component';
import { ContactComponent } from './contact/contact.component';
import { DocumentaryComponent } from './documentary/documentary.component';
import { HomeComponent } from './home/home.component';
import { IndependentComponent } from './independent/independent.component';
import { PressComponent } from './press/press.component';
import { PressArticleComponent } from './press-article/press-article.component';
import { ProjectsComponent } from './projects/projects.component';
import { ScriptsComponent } from './scripts/scripts.component';
import { ServiceComponent } from './service/service.component';
import { TipsComponent } from './tips/tips.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'entertainment-lawyer-about-us', component: AboutComponent },
  { path: 'entertainment-law-articles', component: ArticlesComponent },
  { path: 'entertainment-law-articles/:id', component: ArticleDetailComponent },
  { path: 'clearance-services', component: ClearanceComponent },
  { path: 'entertainment-lawyer-clients', component: ClientsComponent },
  { path: 'entertainment-law-industry-connections', component: ConnectionsComponent },
  { path: 'entertainment-lawyer-contact-us', component: ContactComponent },
  { path: 'documentary-films', component: DocumentaryComponent },
  { path: 'entertainment-lawyer-home', component: HomeComponent },
  { path: 'independent-films', component: IndependentComponent },
  { path: 'entertainment-lawyer-press', component: PressComponent },
  { path: 'entertainment-lawyer-press/:id', component: PressArticleComponent },
  { path: 'entertainment-law-projects', component: ProjectsComponent },
  { path: 'script-review-services', component: ScriptsComponent },
  { path: 'entertainment-law-services', component: ServiceComponent },
  { path: 'entertainment-law-tips', component: TipsComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
