import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

export interface Article { article: string; title: string; date: string; }
export interface ArticleId extends Article { id: string; }

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit {

  private articleCollection: AngularFirestoreCollection<Article>;
  articles: Observable<ArticleId[]>;

  constructor(private db: AngularFirestore) {

    this.articleCollection = db.collection<Article>('/articles');

    this.articles = this.articleCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Article;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  ngOnInit() {
  }

}
