import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule, ScreenTrackingService } from '@angular/fire/analytics';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ServiceComponent } from './service/service.component';
import { ProjectsComponent } from './projects/projects.component';
import { ClientsComponent, ClientDialog } from './clients/clients.component';
import { ConnectionsComponent } from './connections/connections.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent, ContactFormComponent, ContactInfoComponent, ContactMapComponent } from './contact/contact.component';
import { PressComponent } from './press/press.component';
import { TipsComponent } from './tips/tips.component';
import { IndependentComponent } from './independent/independent.component';
import { DocumentaryComponent } from './documentary/documentary.component';
import { ScriptsComponent } from './scripts/scripts.component';
import { ClearanceComponent } from './clearance/clearance.component';
import { ArticlesComponent } from './articles/articles.component';
import { PressArticleComponent } from './press-article/press-article.component';
import { ArticleDetailComponent } from './article-detail/article-detail.component';
import { ContactDialog, PhoneDialog, MapDialog } from './site/contact-dialog.component';
import { SafePipe } from './safe.pipe';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ServiceComponent,
    ProjectsComponent,
    ClientsComponent,
    ClientDialog,
    ConnectionsComponent,
    AboutComponent,
    ContactComponent,
    ContactFormComponent,
    ContactInfoComponent,
    ContactMapComponent,
    PressComponent,
    TipsComponent,
    IndependentComponent,
    DocumentaryComponent,
    ScriptsComponent,
    ClearanceComponent,
    ArticlesComponent,
    PressArticleComponent,
    ArticleDetailComponent,
    ContactDialog,
    PhoneDialog,
    MapDialog,
    SafePipe
  ],
  entryComponents: [
    ClientDialog,
    ContactDialog,
    PhoneDialog,
    MapDialog
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatSidenavModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatSnackBarModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAnalyticsModule,
    AppRoutingModule
  ],
  providers: [ScreenTrackingService],
  bootstrap: [AppComponent]
})
export class AppModule { }
