import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-independent',
  templateUrl: './independent.component.html',
  styleUrls: ['./independent.component.scss']
})
export class IndependentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
