class Tip{
    title: string;
    url: string;
    description: string;
}

export const TIPS: Tip[] = [
    {title: 'Making A Sizzle Reel', url: 'https://www.youtube.com/embed/MtjJJZfAf4c', description: 'Learn how to make a sizzle reel'},
    {title: 'Reality Television Show', url: 'https://www.youtube.com/embed/j7wdpIrDpgM', description: 'Entertainment Lawyer Tip On Reality Television Shows'},
    {title: 'Optioning Book Rights', url: 'https://www.youtube.com/embed/7G2vBPOuuh8', description: 'Entertainment Lawyer Tip On Optioning Book Rights'},
    {title: 'Merchandising', url: 'https://www.youtube.com/embed/RzLUYHFpZdc', description: 'Entertainment Lawyer Tip On Merchandising'},
    {title: 'Marketing Screenplays', url: 'https://www.youtube.com/embed/7UwG5xScdvg', description: 'Entertainment Lawyer Tip On Marketing Screenplays'},
    {title: 'Marketing Of Films', url: 'https://www.youtube.com/embed/VYXv5mtjil4', description: 'Entertainment Lawyer Tip On Marketing Of Films'},
    {title: 'Joint Venture Agreements', url: 'https://www.youtube.com/embed/21Bz-lCcbRw', description: 'Entertainment Lawyer Tip On Joint Venture Agreements'},
    {title: 'Writers', url: 'https://www.youtube.com/embed/tQz0Y-V2Gsc', description: 'Entertainment Lawyer Tip For Writers'},
    {title: 'Buying & Selling Film Libraries', url: 'https://www.youtube.com/embed/1A1sE9Wt0QI', description: 'Entertainment Lawyer Tip On Buying & Selling Film Libraries'},
    {title: 'Dealing With Distribution of Films', url: 'https://www.youtube.com/embed/kjxw-L73DHw', description: 'Entertainment Lawyer Tip On Dealing With Distribution of Films'},
    {title: 'Animation', url: 'https://www.youtube.com/embed/HRHy8qjN5IU', description: 'Entertainment Lawyer Tip On Animation'},
    {title: 'Distribution of Films', url: 'https://www.youtube.com/embed/oT3ZcXyZNCs', description: 'Entertainment Lawyer on Distribution of Films'}
]