import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

import { Mail } from './mail';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})

export class ContactComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact.component.scss']
})

export class ContactFormComponent implements OnInit {
  
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  
  public contactForm: FormGroup;
  
  private sendToEmail: string = 'tedbaer@tedbaerlaw.com';
  private sendFromEmail: string = 'no-reply@tedbaerlaw.com';
  private itemsCollection: AngularFirestoreCollection<Mail>;
  // private items: Observable<Mail[]>;
  private submitted = false;

  constructor(private afs: AngularFirestore, private snackBar: MatSnackBar) {
    this.itemsCollection = afs.collection<Mail>('mail');
    // this.items = this.itemsCollection.valueChanges();
  }
  
  ngOnInit(): void{
      this.contactForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(3)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      subject: new FormControl('', [Validators.required, Validators.minLength(3)]),
      message: new FormControl('', [Validators.required, Validators.minLength(10)])
    });
  }

  get name(){ return this.contactForm.get('name'); }
  get email(){ return this.contactForm.get('email'); }
  get subject(){ return this.contactForm.get('subject'); }
  get message(){ return this.contactForm.get('message'); }

  sendMail(mail: Mail) {
    this.itemsCollection.add(mail)
      .then(function(docRef) {
          console.log("Document written with ID: ", docRef.id);
          //alert('Thank you for contacting us.  We will reply shortly.');
      })
      .catch(function(error) {
          console.error("Error adding document: ", error);
          if(error){
            //alert('Something happened.  Please try again to contact us.');
          }
      });
  }
  
  openSnackBar(message: string){
    this.snackBar.open(message, 'Dismiss' , {
      duration: 20000
    });
  }

  // Use a promise to wait for submit success and then return a message and reset form
  onSubmit(){
    if(this.contactForm.valid){
      this.submitted = true;
      
      const newMail: Mail = {
        from: this.sendFromEmail,
        to: [this.sendToEmail],
        message: {
          subject: this.contactForm.value.subject,
          text: "Name: " + this.contactForm.value.name + "/n Email: " + this.contactForm.value.email + "/n Message: " + this.contactForm.value.message,
          html: "<strong>Name:</strong> " + this.contactForm.value.name + "<br><strong>Email:</strong> " + this.contactForm.value.email + "<br><strong>Message:</strong> " + this.contactForm.value.message
        }
      }

      this.sendMail(newMail);
      this.openSnackBar('Thank you for contacting us.  We will reply shortly.');
      this.contactForm.reset();
      //console.log(this.contactForm.value);
    }
  }
}

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact.component.scss']
})

export class ContactInfoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

@Component({
  selector: 'app-contact-map',
  templateUrl: './contact-map.component.html',
  styleUrls: ['./contact.component.scss']
})

export class ContactMapComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}