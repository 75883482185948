import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

export interface Article { article: string; title: string; date: string; }
export interface ArticleId extends Article { id: string; }

@Component({
  selector: 'app-press',
  templateUrl: './press.component.html',
  styleUrls: ['./press.component.scss']
})

export class PressComponent implements OnInit {

  private articleCollection: AngularFirestoreCollection<Article>;
  articles: Observable<ArticleId[]>;

  constructor(private db: AngularFirestore) {

    this.articleCollection = db.collection<Article>('/press', ref => ref.orderBy('date', 'desc'));

    this.articles = this.articleCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Article;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
   }

  ngOnInit() {
  }

}
