import { Component } from '@angular/core';
import { ContactDialog, PhoneDialog, MapDialog } from './site/contact-dialog.component';

import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {

constructor(public dialog: MatDialog){};

openContactDialog(): void {

  const dialogRef = this.dialog.open(ContactDialog,{
    minWidth: '300px'
  });
}

openPhoneDialog(): void {

  const dialogRef = this.dialog.open(PhoneDialog,{
    minWidth: '300px'
  });
}

openMapDialog(): void {

  const dialogRef = this.dialog.open(MapDialog,{
    minWidth: '300px'
  });
}

  title:string = 'Ted Baer Law';
  currentYear:number = new Date().getFullYear();

  setHeight(element: string): object{

    let object = document.getElementsByClassName(element)[0];
    let height = object.clientHeight;

    return {
      "height": height
    }

  }

}