// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBKyAUYlCfccbCym2keOEOS_0mz6Kzk5z0",
    authDomain: "ted-baer-law.firebaseapp.com",
    databaseURL: "https://ted-baer-law.firebaseio.com",
    projectId: "ted-baer-law",
    storageBucket: "ted-baer-law.appspot.com",
    messagingSenderId: "817403451961",
    appId: "1:817403451961:web:03ea51be11e2a0979f8be8",
    measurementId: "G-5TV1SSWPZ3"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
