<mat-card class="tip-card" *ngFor="let tip of tips">
  <mat-card-header>
    <mat-card-title>{{ tip.title }}</mat-card-title>
  </mat-card-header>
  <iframe mat-card-image
    width="480"
    height="270"
    [src]="tip.url | safe: 'resourceUrl'"
    frameborder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen>
  </iframe>
  <mat-card-content>
    <p>
      {{ tip.description }}
    </p>
  </mat-card-content>
</mat-card>