class Documentary{
    name: string;
    description: string;
}

export const DOCUMENTARIES: Documentary[] = [
    {name: '', description: 'Searching For Augusta'},
    {name: '', description: 'Yellow Roses'},
    {name: '', description: 'Street Dogs of South Central'},
    {name: '', description: 'Tupac Resurrection'},
    {name: '', description: 'Homeland: Four Portraits of Native Action'},
    {name: '', description: 'Hannah Senesh'},
    {name: '', description: 'Why Now? Not Later! ... A Global Warming Warning!'},
    {name: '', description: '500 Nations'},
    {name: '', description: '"Waiting for Lightning" /Danny Way'},
    {name: '', description: 'Flashing On the Sixties'},
    {name: '', description: 'World Shamanism'}
  ];