<h2>TED BAER BIOGRAPHY</h2>
<p>
Ted Baer Law is an entertainment law firm that specializes in handling legal and business negotiations and
documentation (including rights negotiations, which includes, without limitation, negotiations for film and television
libraries, talent negotiations, production financing, distribution and completion bond agreements, and production
legal) for entertainment companies producing television and feature film projects - including, without limitation,
feature and TV documentaries; and also for television and feature film performers, writers, directors, animators,
photographers, and producers, in connection with television feature film (including, without limitation, documentary
features/clearance for films), interactive media, the internet, licensing and merchandising projects, the
representation of individual performers and producers creating entrepreneurial ventures, including drafting of business
plans.
</p>

<p>
J. A. Ted Baer, the founder and principal of the firm, is admitted to both the New York State Bar and the California
State Bar, He began his law practice in New York as a judge advocate for the United States Navy during the Vietnam War.
Upon leaving the Navy, Ted wrote and produced an album of songs, before joining the law department of Columbia Pictures
in New York City. A year later he joined the law firm of Arrow, Silverman and Parcher as an associate. In 1976, he left
the firm to join the business affairs department of CBS Entertainment in New York, where he eventually ran the New York
branch of the department. In 1981, CBS promoted Ted to Vice President, Business Affairs and transferred him to Los
Angeles. In 1983, he became Vice President in charge of all business affairs for MGM/UA Television, leaving in 1984 to
start his own production company, where he wrote and developed a number of film and television projects. He returned to
United Artists in 1985 to help run its new television business affairs office, and finally started his own law firm in
1987, specializing in film and television negotiations.
</p>

<p>
In addition to practicing law, Ted has written and is producing two screenplays, published a collection of poetry, and
had a brief stint at acting with his first (and only) feature role in Ken Loach's "Bread and Roses." Although Ted
resides in Santa Barbara, he spends a good deal of time in Los Angeles working with his clients on their projects. Ted
has two grown sons, who both excel in their own entrepreneurial ventures and his interests include tennis, writing,
cooking and playing the piano. In addition to his law practice, Ted is presently writing short stories, a new
screenplay, and new songs He completed his first short story- “For Esme – No Squalor, Just Love” which is being
considered for publication by several magazines. He has also completed four new songs which he performs occasionally at
open mikes.
</p>

<p>
Ted is a member of the California State Bar Association and has been on the Board of Directors of the Santa Barbara
International Film Festival, Santa Barbara Channels (Public Access Television) (where he was the Board Chair), and the
Santa Barbara Symphony.
</p>

<mat-list>
  <h3 mat-subheader>Educational History</h3>
  <mat-list-item>
    <mat-icon mat-list-icon>arrow_right</mat-icon>
    <h4 mat-line>Bachelor of Arts</h4>
    <p mat-line>Middlebury College</p>
    <p mat-line>Middlebury, Vermont</p>
  </mat-list-item>
  <mat-list-item>
    <mat-icon mat-list-icon>arrow_right</mat-icon>
    <h4 mat-line>Juris Doctorate</h4>
    <p mat-line>Duke University Law School</p>
    <p mat-line>Durham, North Carolina</p>
  </mat-list-item>
  <mat-divider></mat-divider>
  <h3 mat-subheader>Companies</h3>
  <mat-list-item>
    <mat-icon mat-list-icon>arrow_right</mat-icon>
    <h4 mat-line>Columbia Pictures Industries, Inc.</h4>
    <p mat-line>1974-1975</p>
  </mat-list-item>
  <mat-list-item>
    <mat-icon mat-list-icon>arrow_right</mat-icon>
    <h4 mat-line>CBS Entertainment</h4>
    <p mat-line>1976-1983</p>
  </mat-list-item>
  <mat-list-item>
    <mat-icon mat-list-icon>arrow_right</mat-icon>
    <h4 mat-line>MGM/UA Entertainment</h4>
    <p mat-line>1983-1984</p>
  </mat-list-item>
  <mat-list-item>
    <mat-icon mat-list-icon>arrow_right</mat-icon>
    <h4 mat-line>Ted Baer Productions</h4>
    <p mat-line>1984-1986</p>
  </mat-list-item>
  <mat-list-item>
    <mat-icon mat-list-icon>arrow_right</mat-icon>
    <h4 mat-line>MGM/UA Television</h4>
    <p mat-line>f/k/a United Artists Television</p>
    <p mat-line>1986-1987</p>
  </mat-list-item>
  <mat-divider></mat-divider>
  <h3 mat-subheader>Law Firms</h3>
  <mat-list-item>
    <mat-icon mat-list-icon>arrow_right</mat-icon>
    <h4 mat-line>SOLE PRACTITIONER, Santa Barbara</h4>
    <p mat-line>1987 to Present</p>
  </mat-list-item>
  <mat-list-item>
    <mat-icon mat-list-icon>arrow_right</mat-icon>
    <h4 mat-line>OF COUNSEL to LAPOLT LAW</h4>
    <p mat-line>1/02 to 12/09</p>
  </mat-list-item>
  <mat-list-item>
    <mat-icon mat-list-icon>arrow_right</mat-icon>
    <h4 mat-line>OF COUNSEL to THE FOX LAW GROUP, AND LAW OFFICE OF MARK TEMPLE, Los Angeles</h4>
    <p mat-line>1/99 to 12/01</p>
  </mat-list-item>
  <mat-list-item>
    <mat-icon mat-list-icon>arrow_right</mat-icon>
    <h4 mat-line>OF COUNSEL to COOPER, EPSTEIN & HUREWITZ</h4>
    <p mat-line>7/92 to 12/93</p>
  </mat-list-item>
  <mat-list-item>
    <mat-icon mat-list-icon>arrow_right</mat-icon>
    <h4 mat-line>Sole PRACTITIONER/office arrangement with GIPSON, HOFFMAN & PANCIONE</h4>
    <p mat-line>1990/92</p>
  </mat-list-item>
  <mat-list-item>
    <mat-icon mat-list-icon>arrow_right</mat-icon>
    <h4 mat-line>Associate with Arrow, Silverman & Parcher</h4>
    <p mat-line>1975</p>
  </mat-list-item>
</mat-list>