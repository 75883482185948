import { Component, OnInit } from '@angular/core';
import { CONNECTIONS } from './connections';

@Component({
  selector: 'app-connections',
  templateUrl: './connections.component.html',
  styleUrls: ['./connections.component.scss']
})

export class ConnectionsComponent implements OnInit {

  connections = CONNECTIONS;

  constructor() {}

  ngOnInit() {
  }

}
