import { Component, OnInit, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { AngularFirestore } from '@angular/fire/firestore';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
  name: string;
  biography: string;
  task: string;
}

@Component({
selector: 'app-contact-dialog',
templateUrl: 'contact-dialog.html',
})

export class ContactDialog {
  
constructor(
    public dialogRef: MatDialogRef<ContactDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

    onNoClick(): void {
        this.dialogRef.close();
    }

}

@Component({
  selector: 'app-phone-dialog',
  templateUrl: 'phone-dialog.html',
  })
  
  export class PhoneDialog {
    
  constructor(
      public dialogRef: MatDialogRef<ContactDialog>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData) {}
  
      onNoClick(): void {
          this.dialogRef.close();
      }
  
  }

  @Component({
    selector: 'app-map-dialog',
    templateUrl: 'map-dialog.html',
    })
    
    export class MapDialog {
      
    constructor(
        public dialogRef: MatDialogRef<ContactDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {}
    
        onNoClick(): void {
            this.dialogRef.close();
        }
    
    }