<form id="contactForm" class="baer-contact-form" autocomplete="no" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
    <mat-form-field class="form-full-width">
        <mat-label>Full Name</mat-label>
        <input matInput formControlName="name" placeholder="Ex. John Smith" autocomplete="new-password" minlength="3" maxlength="64" required>
        <mat-error *ngIf="name.hasError('minlength') && !name.hasError('required')">
            Please enter atleast 3 characters
        </mat-error>
        <mat-error *ngIf="name.hasError('required')">
            Name is <strong>required</strong>
        </mat-error>
    </mat-form-field>
    <mat-form-field class="form-full-width">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" type="email" placeholder="Ex. john@email.com" autocomplete="new-password" required>
        <mat-error *ngIf="email.hasError('email') && !email.hasError('required')">
            Please enter a valid email address
        </mat-error>
        <mat-error *ngIf="email.hasError('required')">
            Email is <strong>required</strong>
        </mat-error>
    </mat-form-field>
    <mat-form-field class="form-full-width">
        <mat-label>Subject</mat-label>
        <input matInput formControlName="subject" placeholder="Ex. Screenplay Review" autocomplete="new-password" minlength="3" maxlength="128" required>
        <mat-error *ngIf="subject.hasError('minlength') && !subject.hasError('required')">
            Please enter atleast 3 characters
        </mat-error>
        <mat-error *ngIf="subject.hasError('required')">
            Subject is <strong>required</strong>
        </mat-error>
    </mat-form-field>
    <mat-form-field class="form-full-width">
        <mat-label>Type A Message</mat-label>
        <textarea matInput
            #message
            formControlName="message"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"
            placeholder="Ex. I would like consultation for..."
            minlength="10"
            maxlength="1024"
            required>
        </textarea>
        <mat-hint align="end">{{message.value.length}} / 1024</mat-hint>
    </mat-form-field>
    <div class="g-recaptcha"
        data-sitekey="6Lc2sOoUAAAAAJDxDfrTMK6ABc11GxlC6JRaHcXu"
        data-callback="onSubmit"
        data-size="invisible">
    </div>
    <button mat-stroked-button [disabled]="!contactForm.valid" style="margin-top: 10px;">Submit</button>
</form>